import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Inject,
  Injectable,
  InjectionToken,
  Optional,
  PLATFORM_ID,
} from '@angular/core';

export const APP_SSR_COOKIES = new InjectionToken<any>('APP_SSR_COOKIES');

@Injectable({
  providedIn: 'root',
})
export class CustomCookieService {
  private cookieStore: any = {};

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(DOCUMENT) private doc: Document,
    @Optional() @Inject(APP_SSR_COOKIES) private ssrCookies: string
  ) {
    this.parseCookies();
  }

  public parseCookies() {
    let cookies = isPlatformBrowser(this.platformId)
      ? this.doc.cookie
      : this.ssrCookies;

    this.cookieStore = {};
    if (!!cookies === false) {
      return;
    }
    const cookiesArr = cookies.split(';');
    for (const cookie of cookiesArr) {
      const cookieArr = cookie.split('=');
      this.cookieStore[cookieArr[0].trim()] = cookieArr[1];
    }
  }

  get(key: string) {
    this.parseCookies();
    return !!this.cookieStore[key] ? this.cookieStore[key] : null;
  }

  remove(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.doc.cookie = `${key} = ; expires=Thu, 1 jan 1990 12:00:00 UTC; path=/`;
    } else {
      this.ssrCookies = `${key} = ; expires=Thu, 1 jan 1990 12:00:00 UTC; path=/`;
    }
  }

  set(key: string, value: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.doc.cookie = key + '=' + (value || '');
    } else {
      this.ssrCookies = key + '=' + (value || '');
    }
  }
}
